import React from "react";

export default function DobbleQuotes() {
  return (
    <svg
      width="15"
      height="13"
      viewBox="0 0 15 13"
      fill="none"
      xmlns="https://www.w3.org/2000/svg"
    >
      <path
        d="M10.8059 13C10.2577 12.8567 9.79715 12.6196 9.42434 12.2888C9.05153 11.958 8.75 11.5831 8.51974 11.1641C8.28947 10.7341 8.125 10.282 8.02632 9.80789C7.92763 9.33376 7.87829 8.87065 7.87829 8.41858C7.87829 7.66879 7.96601 6.96862 8.14145 6.31807C8.32785 5.66751 8.57456 5.06658 8.88158 4.51527C9.19956 3.96395 9.56688 3.45674 9.98355 2.99364C10.4002 2.53053 10.8443 2.11153 11.3158 1.73664C11.7982 1.36175 12.2917 1.03096 12.7961 0.744275C13.3114 0.457591 13.8158 0.2095 14.3092 0L15 1.1743L14.9671 1.53817C14.5395 1.68151 14.1173 1.94063 13.7007 2.31552C13.284 2.67939 12.9112 3.10941 12.5822 3.6056C12.2533 4.10178 11.9901 4.64758 11.7928 5.243C11.5954 5.8274 11.4967 6.40628 11.4967 6.97964C11.4967 7.33248 11.5406 7.6743 11.6283 8.00509C11.727 8.33588 11.8531 8.62807 12.0066 8.88168C12.1711 9.13528 12.3629 9.34478 12.5822 9.51018C12.8015 9.67557 13.0428 9.77481 13.3059 9.80789L13.2237 10.486L10.8059 13ZM2.92763 13C2.37939 12.8567 1.91886 12.6196 1.54605 12.2888C1.17325 11.958 0.87171 11.5831 0.641447 11.1641C0.411184 10.7341 0.246711 10.282 0.148026 9.80789C0.0493423 9.33376 0 8.87065 0 8.41858C0 7.66879 0.0877193 6.96862 0.263158 6.31807C0.449561 5.66751 0.696272 5.06658 1.00329 4.51527C1.32127 3.96395 1.6886 3.45674 2.10526 2.99364C2.52193 2.53053 2.96601 2.11153 3.4375 1.73664C3.91996 1.36175 4.41338 1.03096 4.91776 0.744275C5.43311 0.457591 5.9375 0.2095 6.43092 0L7.12171 1.1743L7.08882 1.53817C6.66118 1.68151 6.23903 1.94063 5.82237 2.31552C5.4057 2.67939 5.0329 3.10941 4.70395 3.6056C4.375 4.10178 4.11184 4.64758 3.91447 5.243C3.71711 5.8274 3.61842 6.40628 3.61842 6.97964C3.61842 7.33248 3.66228 7.6743 3.75 8.00509C3.84868 8.33588 3.97478 8.62807 4.12829 8.88168C4.29276 9.13528 4.48465 9.34478 4.70395 9.51018C4.92325 9.67557 5.16447 9.77481 5.42763 9.80789L5.3454 10.486L2.92763 13Z"
        fill="white"
      />
    </svg>
  );
}
