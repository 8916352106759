import React from "react";
import { motion } from "framer-motion";

const svgVariants = {
  hidden: { rotate: 0 },
  visible: {
    rotate: 0,
    transition: { duration: 1 },
  },
};
const pathVariants = {
  hidden: {
    opacity: 0,
    pathLength: 0,
  },
  visible: {
    opacity: 1,
    pathLength: 1,
    transition: {
      duration: 1,
      delay: 0.5,
      ease: "easeInOut",
    },
  },
};
export default function SmallRoundSvgIcon() {
  return (
    <motion.svg
      variants={svgVariants}
      initial="hidden"
      whileInView="visible"
      width="193"
      height="74"
      viewBox="0 0 193 74"
      fill="none"
      xmlns="https://www.w3.org/2000/svg"
      className="!w-full !h-full"
    >
      <motion.path
        variants={pathVariants}
        initial="hidden"
        whileInView="visible"
        d="M106.55 12.3648C71.0905 9.17027 0.430639 10.3473 1.46235 40.6095C2.75155 78.4378 180.691 81.9688 190.684 52.2107C200.677 22.4526 121.378 -1.25398 87.5305 2.27705"
        stroke="#FF8E5E"
        strokeWidth="2.81258"
        strokeLinecap="round"
      />
    </motion.svg>
  );
}
